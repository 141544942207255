import * as React from "react";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import AiLanding from "../../components/pages/ai/AiLanding";
import AiThemingImage from "../../components/pages/ai/AiThemingImageupdate";
import AiThemingMetaupdate from "../../components/pages/ai/AiThemingMetaupdate";
import AiTextAssistant from "../../components/pages/ai/AITextAssistant";
import AiHeatmap from "../../components/pages/ai/AiHeatmap";
import AiWireframe from "../../components/pages/ai/AiWireframe";
import AiStyleExtraction from "../../components/pages/ai/AiStyleExtraction";
import AiMore from "../../components/pages/ai/AiMore";
import Content from "../../components/layout/Content/Content";
import Shoutout from "../../components/pages/signup/Shoutout";
import AiScreenshot from "../../components/pages/ai/AiScreenshot";

const DesignAssistant = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard's AI-powered Design Assistant | Uizard"
      description="Say hello to Design Assistant, our AI-powered UI design tool. Bring your app ideas to life rapidly and create mockups in minutes, all with the power of AI."
      url="/design-assistant/"
    />

    <AiLanding
      tagline="Design Assistant"
      imageA={data.landingImageA.childImageSharp}
      imageB={data.landingImageB.childImageSharp}
      headline="AI-powered design for apps, websites, and UIs"
      description="Uizard's Design Assistant is here to change the way you think about design."
      tagAsTitle={true}
    />

    <AiScreenshot
      tagline="Web Design"
      headline="Edit screenshots automatically"
      description="<a:https://uizard.io/ai-design/>AI design</a> is here! Upload screenshots to Uizard and transform them into editable mockups in a matter of seconds. Scan screen grabs of your favorite apps, website, and UIs and tweak them for your own use case effortlessly."
      imageA={data.hificonversionImageA.childImageSharp}
      imageB={data.hificonversionImageB.childImageSharp}
      imageAlt="convert screen shot into editable UI mockup"
      videoName="uizard-scan-screenshot"
      link=""
    />

    <AiHeatmap
      tagline="UI Design"
      headline="Introducing: Attention Heatmap"
      description="Bring the power of generative AI into your workflow. Use Uizard to generate attention heatmaps to predict the areas of your UI design where users will focus their attention the most."
      description2="Get key insights in seconds before you even start doing user testing."
      imageAlt="uizard ai heat map"
      imageA={data.heatmapImageA.childImageSharp}
      imageB={data.heatmapImageB.childImageSharp}
      videoName="uizard-heatmap-generation"
      link=""
    />

    <AiWireframe
      tagline="App Design"
      headline="Turn sketches into editable mockups"
      description="Whether you scribble your app ideas down on a whiteboard, in a notebook, or on a scrap of paper, transforming your idea into a <a:https://uizard.io/prototyping/>UI prototype</a> has never been so easy. Scan your product sketches with Uizard's Scribble Scanner now and watch the AI app design magic unfold."
      imageA={data.wireframeScanImageA.childImageSharp}
      imageB={data.wireframeScanImageB.childImageSharp}
      imageAlt="uizard wireframe mode to render designs in low-fidelity."
      videoName="uizard-wireframe-scan"
      link=""
    />

    <AiTextAssistant
      tagline="Copy Generation"
      headline="Generate text for your UI Mockups"
      description="Looking for the perfect copy or placeholder text for your UI design? Use the power of Uizard's Text Assistant to generate page titles, subheadings, and CTA text."
      description2="Create variants of your mockup text and swap it out for the perfect AI-generated replacement in next to no time."
      imageAlt="uizard text assistant"
      imageA={data.textAssistantImageA.childImageSharp}
      imageB={data.textAssistantImageB.childImageSharp}
      videoName="uizard-text-assistant"
      link=""
    />

    <AiThemingMetaupdate
      imageA={data.themingImageA.childImageSharp}
      imageB={data.themingImageB.childImageSharp}
      headline="Import your existing design assets"
    />

    <AiStyleExtraction
      headline="Extract design styles from screenshots"
      description="Uizard's AI design tools can even extract colors, stylization, typography, and other components from any app screenshot, meaning you can transfer a design style from one project to another with just a few clicks of a button."
      imageAlt="Uizard supports theming to render designs in high-fidelity."
      imageA={data.themeGenerationImageA.childImageSharp}
      imageB={data.themeGenerationImageB.childImageSharp}
      videoName="uizard-theme-generation"
      link=""
    />

    <AiThemingImage
      imageA={data.styleImageA.childImageSharp}
      imageB={data.styleImageB.childImageSharp}
      headline="Create themes from images"
    />

    {/*
    <AiFront
      headline="Front-end code"
      imageA={data.frontImageA.childImageSharp}
      imageB={data.frontImageB.childImageSharp}
    />
    */}

    <AiMore />

    <Content>
      <Shoutout
        headline="AI-powered design is here!"
        description="Join the AI design revolution with Uizard"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default DesignAssistant;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    landingImageA: file(relativePath: { eq: "ai/landing-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1028, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    landingImageB: file(relativePath: { eq: "ai/landing-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    sketchingImageA: file(relativePath: { eq: "ai/sketching-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1272, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    sketchingImageB: file(relativePath: { eq: "ai/sketching-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 710, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    heatmapImageA: file(relativePath: { eq: "ai/heatmap-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    heatmapImageB: file(relativePath: { eq: "ai/heatmap-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageA: file(
      relativePath: { eq: "ai/HifiConversion-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageB: file(
      relativePath: { eq: "ai/HifiConversion-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    textAssistantImageA: file(
      relativePath: { eq: "ai/uizard-text-assistant-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    textAssistantImageB: file(
      relativePath: { eq: "ai/uizard-text-assistant-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScanImageA: file(
      relativePath: { eq: "home/uizard-wireframe-scan-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScanImageB: file(
      relativePath: { eq: "home/uizard-wireframe-scan-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themingImageA: file(relativePath: { eq: "ai/theming-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1038, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themingImageB: file(relativePath: { eq: "ai/theming-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1336, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageA: file(
      relativePath: { eq: "home/uizard-theme-generation-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageB: file(
      relativePath: { eq: "home/uizard-theme-generation-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    styleImageA: file(relativePath: { eq: "ai/style-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1038, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    styleImageB: file(relativePath: { eq: "ai/style-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1217, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
