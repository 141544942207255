import * as React from "react";
import Styled from "styled-components/macro";
import Image, { GatsbyImageProps } from "gatsby-image";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../texts/Headline";
import Description from "../../texts/Description";
import Content from "../../layout/Content/Content";
import MediaQuery from "../../layout/MediaQuery";

const ImageTop = Styled.div`
    width: 100%;
    height: 20rem;
    background-color: #D8D8D8;
    margin-top: 4rem;
    
    ${Media.greaterThan("medium")`
        margin-top: 0;
        width: 83%;
        height: 25rem;
    `}
`;

const ImageBottom = Styled(Image)`
    width: 100%;
    margin-top: 1.5rem;
`;

const Columns = Styled.div`
    display: flex;
    margin-top: 8rem;
    margin-bottom: 8rem;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    
    ${Media.greaterThan("medium")`
        flex-direction: row;
        margin-top: 18rem;
    `}
`;

const ColumnText = Styled.div`
    width: 100%;
    
    ${Media.greaterThan("large")`
        padding-right: 9.5rem;
    `}
    
    ${Media.greaterThan("medium")`
        width: 50%;
    `}
`;

const ColumnIllustration = Styled.div`
    width: 35.5rem;

    ${Media.greaterThan("medium")`
        width: 38rem;
        margin-right: 8rem;
    `}
    
     ${Media.greaterThan("large")`
        width: 48rem;
        margin-right: 16.8rem;
    `}
   
`;

type AiThemingMetaProps = {
  headline: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
};

const AiThemingMeta = ({ headline, imageA, imageB }: AiThemingMetaProps) => (
  <Content>
    <Columns>
      <ColumnIllustration>
        <MediaQuery lessThan="medium">
          <ImageBottom
            fluid={"fluid" in imageB ? imageB.fluid : ""}
            alt="Image showcasing Uizard's capability to turn images into customizable design themes."
          />
        </MediaQuery>

        <MediaQuery greaterThan="medium">
          <ImageBottom
            fluid={"fluid" in imageA ? imageA.fluid : ""}
            alt="Image showcasing Uizard's capability to turn images into customizable design themes."
          />
        </MediaQuery>
      </ColumnIllustration>
      <ColumnText>
        <Headline>{headline}</Headline>
        <Description>
          Chances are, you already have some design assets laying around for
          your brand or MVP. Uizard can automatically extract styling properties
          and components from a URL, an app screenshot, or from a Sketch file.
          <br />
          <br />
          This means that you can easily import your existing design assets into
          Uizard to fast-track your design flow.
        </Description>
      </ColumnText>
    </Columns>
  </Content>
);

export default AiThemingMeta;
