import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Image, { GatsbyImageProps } from "gatsby-image";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../texts/Headline";
import Button from "../../buttons/Button";
import Tagline from "../../texts/Tagline";
import Description from "../../texts/Description";
import Content from "../../layout/Content/Content";
import MediaQuery from "../../layout/MediaQuery";
import GoldenKittyBadge from "../../../components/pages/home/GoldenKittyBadge";

const ImageA = Styled(Image)`
`;

const Columns = Styled.div`
    display: flex;
    margin: 10rem 0 0 0;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    
    ${Media.greaterThan("900px")`
        flex-direction: row;
        margin: 18rem 0 0 0;
    `}
`;

const ColumnText = Styled.div`
    width: 100%;
    
     ${Media.greaterThan("900px")`
        width: 50%;
    `}
`;

const ColumnIllustration = Styled.div`
    width: 75%;
    max-width: 399px;
    margin-top: 5rem;
    
     ${Media.greaterThan("900px")`
        margin-top: 0;
        max-width: 634px;
        margin-left: 9rem;
    `}
`;

const SignupContainer = Styled.div`
  max-width: 33.5rem;
  
  ${Media.greaterThan("900px")`
        max-width: 43rem;
    `}
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const NavButtonLink = Styled.a`
  
`;

const GoldenKittyBadgeContainer = Styled.div`
  margin: 5rem 0 12rem 0;

  ${Media.greaterThan("900px")`
      margin: 10rem 0 16rem 0
  `}
`;

type AiLandingProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  tagAsTitle?: boolean;
};

export default ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  tagAsTitle,
}: AiLandingProps) => (
  <Content narrow>
    <Columns>
      <ColumnText>
        {tagline && tagAsTitle && (
          <TaglineExtended as="h1">{tagline}</TaglineExtended>
        )}
        {tagAsTitle && <Headline as="h2">{headline}</Headline>}

        {tagline && !tagAsTitle && <TaglineExtended>{tagline}</TaglineExtended>}
        {!tagAsTitle && <Headline>{headline}</Headline>}

        <Description>{description}</Description>

        <NavButtonLink href={`https://${constants.APP_DOMAIN}/auth/sign-up`}>
          <Button solid>Sign up for free</Button>
        </NavButtonLink>
      </ColumnText>
      <ColumnIllustration>
        <MediaQuery lessThan="medium">
          <ImageA
            fluid={"fluid" in imageB ? imageB.fluid : null}
            alt="Uizard's AI can assist you every step of the way"
          />
        </MediaQuery>

        <MediaQuery greaterThan="medium">
          <ImageA
            fluid={"fluid" in imageA ? imageA.fluid : null}
            alt="Uizard's AI can assist you every step of the way"
          />
        </MediaQuery>
      </ColumnIllustration>
    </Columns>
    <GoldenKittyBadgeContainer>
      <GoldenKittyBadge />
    </GoldenKittyBadgeContainer>
  </Content>
);
