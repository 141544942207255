import * as React from "react";
import { Component } from "react";
import Styled from "styled-components/macro";

const GoldenKitty = Styled.a`
  background-color: rgb(17, 16, 14);
  color: #FFFFFF;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  font-size: 2rem;
  border-radius: 0.8rem;
  width: fit-content;
  padding-right: 2rem;
`;

const KittyImg = Styled.img`
  width: 60px;
  height: 60px;
  margin: 1rem;
`;

const Label = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PH = Styled.span`
  font-size: 1.5rem;
`;

const BestProduct = Styled.p`
  font-size: 2rem;
  padding: 0;
  margin: 0;
`;

class GoldenKittyBadge extends Component {
  render() {
    // eslint-disable-line
    return (
      <GoldenKitty
        href="https://www.producthunt.com/posts/uizard-3"
        target="_blank"
      >
        <KittyImg
          src="https://ph-static.imgix.net/golden-kitty/2021/intro_kitty.png?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=100&h=100&fit=max&dpr=2"
          alt="Product Hunt Golden Kitty Award logo"
        />
        <Label>
          <PH>Product Hunt 2021</PH>
          <BestProduct>Best AI Product of the Year 🏆</BestProduct>
        </Label>
      </GoldenKitty>
    );
  }
}

export default GoldenKittyBadge;
