import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Content from "../../layout/Content/Content";
import Button from "../../buttons/Button";
import { HybridLink } from "../../layout/Footer";

const ExtendedLink = Styled(HybridLink)``;

const ExtendedContent = Styled(Content)`
    margin-top: 12rem;
    margin-bottom: 10rem;
    background: ${(props) => props.theme.colors.gray.mediumer};
    padding: 9rem;
    border-radius: 2rem;
    
    ${Media.lessThan("medium")`
        padding: 3rem;
        margin-top: 7rem;
    `}
`;

const ExtendedDescription = Styled.div`
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
    
  ${Media.lessThan("medium")`
    font-size: 2.4rem;
 `}
`;

const ExtendedButton = Styled(Button)`
    margin: 3.5rem auto 0 auto;
    display: block;
    border-radius: 1rem;
    font-size: 1.6rem;
    
    ${Media.lessThan("medium")`
        margin: 1.5rem auto 0 auto;
    `}
`;

const AiMore = () => (
  <ExtendedContent>
    <ExtendedDescription>
      Curious about how all this AI magic is even possible?
    </ExtendedDescription>
    <ExtendedLink to="/research/">
      <ExtendedButton type="submit" variant="black" solid>
        Read our research page
      </ExtendedButton>
    </ExtendedLink>
  </ExtendedContent>
);

export default AiMore;
